import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

export const useBetaMode = () => {
  const [isBetaMode, setBetaMode] = useState();
  const hasBetaMembership = useSelector(
    (state) => state.control.auth.hasBetaMembership,
  );
  const betaEnabled = useSelector(
    (state) => state.control.userPreference.betaEnabled,
  );

  useEffect(() => {
    if (hasBetaMembership) {
      if (betaEnabled) {
        setBetaMode(true);
      } else {
        setBetaMode(false);
      }
    } else {
      setBetaMode(false);
    }
  }, [hasBetaMembership, betaEnabled]);

  return isBetaMode;
};
